import { SecurityContext } from '@lib';
import { navigate } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useEffect } from 'react';

type LoginPageProps = {};

const LoginPage: FC<LoginPageProps> = () => {
  const { loginControl } = useContext(SecurityContext);

  useEffect(() => {
    if (loginControl) {
      navigate('/');
      loginControl.open();
    }
  }, [loginControl]);

  return null;
};

export default LoginPage;
